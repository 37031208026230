import React from 'react';
import './NavBar.css';
import './NavBar2.css';

class NavBarLink extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			showing: false
		};

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e)
	{
		if (typeof this.props.content !== "undefined")
		{
			e.preventDefault();

			this.setState({
				showing: !this.state.showing
			});
		}
	}

	render()
	{
		return (
			<li>
				<a className="NavBarLink" href={this.props?.url} onClick={this.handleClick}>
					<i className={this.props.icon}></i> {this.props.name} {this.props?.content ? (this.state.showing ? (<i class="fas fa-angle-up"></i>) : (<i class="fas fa-angle-down"></i>)) : ""}
				</a>
				{this.state.showing ? this.props.content : ""}
			</li>
		);
	}
}

function NavBarServices(props)
{
	return (
		<div className="NavBarServices">
			{props.content}
		</div>
	);
}

function NavBarLinkService(props)
{
	return (
		<div className="NavBarLinkService">
			<a className="NavBarLink" href={props.url}>
				<img src={props.image} alt="Nav Bar Link Icon" />
				<p>{props.name}</p>
			</a>
		</div>
	);
}

class NavBar extends React.Component
{
	constructor(props)
	{
		super(props);

		this.handleScroll = this.handleScroll.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.NavBarContainer = this.NavBarContainer.bind(this);

		this.style1 = this.style1.bind(this);
		this.style2 = this.style2.bind(this);

		this.state = {
			stickyTop: -1,
			sticking: false,
			style: typeof this.props.style === "undefined" ? 1 : this.props.style
		};
	}

	componentDidMount()
	{
		this.setState({
			stickyTop: window.$(".NavBar").get(0).offsetTop
		});
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount()
	{
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.handleResize);
	}

	handleScroll(e)
	{
		if (this.state.stickyTop >= 0)
		{
			if (window.pageYOffset > this.state.stickyTop && this.state.sticking === false)
			{
				this.setState({
					sticking: true
				});
			}
			else if (window.pageYOffset < this.state.stickyTop && this.state.sticking === true)
			{
				this.setState({
					sticking: false
				});
			}
		}
	}

	handleResize(e)
	{
		this.setState({
			stickyTop: window.$(".NavBar").get(0).offsetTop
		});
	}

	NavBarContainer()
	{
		var services = [];
		services.push({
			name: "3D Animation",
			url: "#",
			image: process.env.PUBLIC_URL + "/images/services/3danimation.jpg"
		});
		services.push({
			name: "3D Modelling",
			url: "#",
			image: process.env.PUBLIC_URL + "/images/services/3dmodelling.jpg"
		});
		services.push({
			name: "Game Design",
			url: "#",
			image: process.env.PUBLIC_URL + "/images/services/gamedesign.jpg"
		});
		services.push({
			name: "Gameplay Programming",
			url: "#",
			image: process.env.PUBLIC_URL + "/images/services/gameplayprogramming.jpg"
		});
		services.push({
			name: "Gameplay Testing",
			url: "#",
			image: process.env.PUBLIC_URL + "/images/services/gameplaytesting.jpg"
		});
		services.push({
			name: "Tool Programming",
			url: "#",
			image: process.env.PUBLIC_URL + "/images/services/toolprogramming.jpg"
		});

		var links = [];
		links.push({
			name: "Home",
			url: "/",
			icon: "fas fa-home"
		});
		links.push({
			name: "About",
			url: "/about/",
			icon: "fas fa-info-circle"
		});
		links.push({
			name: "Blog",
			url: "/blog/",
			icon: "fas fa-newspaper"
		});
		links.push({
			name: "Services",
			urls: services,
			icon: "fas fa-briefcase"
		});
		links.push({
			name: "Contact",
			url: "/contact/",
			icon: "fas fa-address-book"
		});
		links = links.map((link, index) =>
		{
			if (typeof link.urls === "undefined")
				return <NavBarLink key={index} url={link.url} icon={link.icon} name={link.name} />;
			else
			{
				var urls = link.urls.map((url, index2) =>
				{
					return <NavBarLinkService key={index2} url={url.url} name={url.name} image={url.image} />;
				});

				return <NavBarLink key={index} url="#" content={<NavBarServices content={urls} />} icon={link.icon} name={link.name} />;
			}
		});
		return (
			<div className="NavBar-container">
				<div className="logo">
					<a href="/">
						<img src={process.env.PUBLIC_URL + "/images/logo_256.png"} alt="mini icon" />
					</a>
				</div>
				<div className="links">
					<ul>
						{links}
					</ul>
				</div>
			</div>
		);
	}

	style1()
	{
		return (
			<div className={(this.state.sticking ? "NavBar sticky" : "NavBar")}>
				{this.NavBarContainer()}
			</div>
		);
	}

	style2()
	{
		return (
			<div className={(this.state.sticking ? "NavBar2 sticky" : "NavBar2")}>
				{this.NavBarContainer()}
			</div>
		);
	}

	render()
	{
		//left-side logo
		//right-aligned link list
		//about
		//services
		//client list
		//contact
		switch (this.props.style)
		{
			case 1:
				return this.style1();
			case 2:
				return this.style2();
			default:
				return this.style1();
		}
	}
}
export default NavBar;