import React from 'react';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import Feature1 from './components/Feature1/Feature1';
import Introduction from './components/Introduction/Introduction';
import Feature2 from './components/Feature2/Feature2';
import Company from './components/Company/Company';

class App extends React.Component
{
	render()
	{
		return (
			<div className="App">
				<Feature1 />
				<NavBar />
				<Introduction />
				<Feature2 />
				<Company />
			</div>
		);
	}
}
export default App;