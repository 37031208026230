import React from 'react';
import './Feature1.css';

class Feature1 extends React.Component
{
	handleScrollClick(e)
	{
		e.preventDefault();
		window.$('html, body').animate({
			scrollTop: window.$("#Introduction").offset().top - window.$(".NavBar").height()
		}, 1000);
	}

	render()
	{
		return (
			<div id="home" className="Feature1" style={{
				"background-image": `url('${process.env.PUBLIC_URL}/images/flat.png')`
			  }}>
				<div className="Feature1-container">
					<div className="logo">
						<div className="logo-container">
							<img className="logo" src={process.env.PUBLIC_URL + "/images/logo_256.png"} alt="Brand" />
						</div>
						<img src={process.env.PUBLIC_URL + "/images/BlazingGatorGamesTitle.png"} alt="title" />
					</div>
					<p className="motto">Ignite Your Dreams</p>
					<button className="scroll" title="Scroll Down" onClick={this.handleScrollClick}><span className="chevron"></span></button>
				</div>
			</div>
		);
	}
}
export default Feature1;