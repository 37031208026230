import React from 'react';
import './Company.css';


function TeamMember(props)
{
	return (
		<div className="TeamMember">
			<a href={props.url} target="_blank" rel="noreferrer">
				<img src={props.image} alt={props.name} />
			</a>
			<h1>{props.name}</h1>
			<p>{props.title}</p>
		</div>
	);
}

class Company extends React.Component
{
	constructor(props)
	{
		super(props);

		var members = [];

		members.push({
			url: `https://www.neldermakesgames.com/`,
			image: `${process.env.PUBLIC_URL}/images/profiles/aaron_nelder.jpg`,
			name: "Aaron Nelder",
			title: "Programmer"
		});

		members.push({
			url: `https://www.ademkonportfolio.com/`,
			image: `${process.env.PUBLIC_URL}/images/profiles/adem_kon.jpg`,
			name: "Adem Kon",
			title: "Artist"
		});

		members.push({
			url: `https://isaihianeufeld.ca/`,
			image: `${process.env.PUBLIC_URL}/images/profiles/isaihia_neufeld.jpg`,
			name: "Isaihia Neufeld",
			title: "Designer"
		});

		members.push({
			url: `https://www.jakehardy.dev`,
			image: `${process.env.PUBLIC_URL}/images/profiles/jake_hardy.jpg`,
			name: "Jake Hardy",
			title: "Designer"
		});

		members.push({
			url: `https://mattmackie13.wixsite.com/mattmackie`,
			image: `${process.env.PUBLIC_URL}/images/profiles/matt_mackie.jpg`,
			name: "Matt Mackie",
			title: "Artist"
		});

		members.push({
			url: `https://mylesvanevery.ca/`,
			image: `${process.env.PUBLIC_URL}/images/profiles/myles_vanevery.jpg`,
			name: "Myles Vanevery",
			title: "Programmer"
		});

		//sort by name
		members.sort((a, b) =>
		{
			if (a.name < b.name)
			{
				return -1;
			}
			else if (a.name > b.name)
			{
				return 1;
			}
			else
			{
				return 0;
			}
		});

		this.state = { members: members };
	}

	render()
	{
		const teamlist = this.state.members.map((member, index) =>
		{
			return <TeamMember key={index} url={member.url} image={member.image} name={member.name} title={member.title} />;
		});
		return (
			<div className="Company">
				<h1>Meet the Gator Crew</h1>
				<p>Get to know the <span className="callout">toughest</span> gators ever!</p>
				<div className="team-members">{teamlist}</div>
			</div>
		);
	}
}
export default Company;