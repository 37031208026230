import React from 'react';
import './Feature2.css';

function Feature2AElement(props)
{
	var aElement = <a href={props.url} target="_blank" rel="noopener noreferrer">{props.children}</a>;
	if (props.url.indexOf("blazinggatorgames.ca") >= 0)
	{
		aElement = <a href={props.url}>{props.children}</a>;
	}
	return aElement;
}

function Feature2Link(props)
{
	return (
		<div className="Feature2Link">
			<Feature2AElement url={props.url} title={props.title} description={props.description}>
				<img src={props.image} alt={props.title} />
				<h1 className="title">{props.title}</h1>
				<p className="description">{props.description}</p>
			</Feature2AElement>
		</div>
	);
}

function Feature2(props)
{
	return (
		<div className="Feature2" style={{
			"background-image": `url('${process.env.PUBLIC_URL}/images/grunt_bg.jpg')`
		  }}>
			<div className="Feature2-container">
				<h1>Video Games</h1>
				<h2>It is what we do best</h2>
				<div className="Feature2Links">
					<Feature2Link url="https://www.instagram.com/p/CZur9E-FCMS/" image={process.env.PUBLIC_URL + "/images/Tardigraid.jpg"} title="Tardigraid" description="Our Work in Progress" />
					<Feature2Link url="https://blazinggatorgames.ca/blog/4/meet-the-tardigrades" image={process.env.PUBLIC_URL + "/images/grunt01.jpg"} title="Tardigrades" description="View the Enemies" />
					<Feature2Link url="https://www.instagram.com/p/CZuvWB2F1gT/" image={process.env.PUBLIC_URL + "/images/laser_pistol01.jpg"} title="Laser Pistol" description="View the Weapon" />
					<Feature2Link url="https://www.instagram.com/p/CZuwd3yFSER/" image={process.env.PUBLIC_URL + "/images/fusion_blaster01.jpg"} title="Fusion Blaster" description="View the Weapon" />
				</div>
			</div>
		</div>
	);
}
export default Feature2;