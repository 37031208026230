import React from 'react';
import './Introduction.css';

class Introduction extends React.Component
{
	render()
	{
		return (
			<div id="Introduction" className="Introduction">
				<div className="Introduction-container">
					<h1>Welcome to Blazing Gator Games</h1>
					<h2>We are a startup game development business</h2>
					<p className="lead">Our mission is to establish ourselves as a provider of high-quality games, and to make this goal a core design pillar for any product we make.</p>
					<p>Our mission defines who we are.</p>
					<span className="sep"></span>
					<a className="readmore" href="/about/">Read more about us</a>
				</div>
			</div>
		);
	}
}
export default Introduction;